<template>
	<div class="lazy-picture-container" :class="classname" ref="container">
		<picture >
			<img :data-src="img" width="auto" height="auto" :alt="alt"  />
		</picture>
	</div>
</template>

<script>
export default {
	name: "LazyPicture",
	props: {
		img: {
      required: true // 可选项，指示该prop是否是必需的，默认为false
    },
		classname: {
      required: false // 可选项，指示该prop是否是必需的，默认为false
    },
		alt: {
			required: false,
		}
	},
	mounted() {
		this.addEventListeners();
	},
	methods: {
		isInViewport(element) {
			var rect = element.getBoundingClientRect();
			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <=
					(window.innerHeight ||
						document.documentElement.clientHeight) &&
				rect.right <=
					(window.innerWidth || document.documentElement.clientWidth)
			);
		},
		loadPicture() {
			var container = this.$refs.container;
			var pictureElement = container.querySelector("picture");

			if (this.isInViewport(container)) {
				var imgElement = pictureElement.querySelector("img");
				imgElement.src = imgElement.dataset.src;


				window.removeEventListener("scroll", this.loadPicture);
				window.removeEventListener("resize", this.loadPicture);
			}
		},
		addEventListeners() {
			window.addEventListener("scroll", this.loadPicture);
			window.addEventListener("resize", this.loadPicture);
			this.loadPicture();
		},
	},
};
</script>
